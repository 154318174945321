import React from 'react'
import { compose, pure } from 'recompose'
import { graphql } from 'gatsby'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'
import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import SechageEtBrillance from '../../components/methode-raypath/SechageEtBrillance'

const SechageEtBrillancePage = ({ data, t }) => {
  const images = {
    image1: data.image1.childImageSharp,
    image2: data.image2.childImageSharp,
    image3: data.image3.childImageSharp
  }

  return (
    <SecondaryLayout subtitle={t('titre')} page="ComponentMethodeRaypathSechageEtBrillance">
      <SechageEtBrillance images={images} />
    </SecondaryLayout>
  )
}

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('sechage-et-brillance')
)

export default echance(SechageEtBrillancePage)

export const pageQuery = graphql`
  query SechageEtBrillanceImageQuery {
    image1: file(relativePath: { eq: "methode/sechage-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image2: file(relativePath: { eq: "methode/sechage-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image3: file(relativePath: { eq: "methode/sechage-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
  }
`
